import process from "process";

export function reducer(
    state = {
        view: "home",
        card: process.env.MIX_CARD_PREFIX,
        balance: null,
        rechargeAmount: "",
        rechargePolicy: false,
        pin: "",
        iban: "",
        owner: "",
        unloadStatus: "",
        unloadTime: 0,
        unloadTimeDiff: 0,
        errors: {},
        pending: "",
    },
    action
) {
    switch (action.type) {
        case "UPDATE_VIEW":
            return { ...state, view: action.view };

        case "UPDATE_CARD":
            if (action.card == null) return "";

            action.card = action.card.replace(/[^0-9]/g, "");

            return { ...state, card: action.card };

        case "UPDATE_PENDING":
            return { ...state, pending: action.pending };

        case "UPDATE_BALANCE":
            return { ...state, balance: action.balance };

        case "UPDATE_AMOUNT": {
            if (action.amount == null) return "";

            let value = action.amount.replace(/[^0-9.,]/g, "");
            value = value.replace(/[.]/g, ",");
            value = value.indexOf(",") == 0 ? value.substring(1) : value;

            let firstOccuranceIndex = value.search(/,/) + 1;
            const whole =
                firstOccuranceIndex == 0
                    ? value.substr(0, 3)
                    : value.substr(0, firstOccuranceIndex).substr(0, 4);
            let fraction =
                firstOccuranceIndex == 0
                    ? ""
                    : value
                          .slice(firstOccuranceIndex)
                          .replace(/,/g, "")
                          .substr(0, 2);
            value = whole + fraction;

            return { ...state, rechargeAmount: value };
        }

        case "UPDATE_POLICY":
            return { ...state, rechargePolicy: action.policy };

        case "UPDATE_PIN":
            return { ...state, pin: action.pin.replace(/[^0-9]/g, "") };

        case "UPDATE_IBAN":
            return {
                ...state,
                iban: action.iban.replace(/[^0-9a-zA-z]/g, "").toUpperCase(),
            };

        case "UPDATE_OWNER":
            return { ...state, owner: action.owner };

        case "UPDATE_UNLOAD_STATUS":
            return { ...state, unloadStatus: action.unloadStatus };

        case "UPDATE_UNLOAD_TIME":
            return { ...state, unloadTime: action.unloadTime };

        case "UPDATE_UNLOAD_TIME_DIFF":
            return {
                ...state,
                unloadTimeDiff: (Date.now() - state.unloadTime) / 1000,
            };

        case "ADD_ERROR": {
            let error = {};
            error[action.error] = { message: action.message || "" };
            return {
                ...state,
                errors: { ...state.errors, ...error },
            };
        }

        case "DELETE_ERROR": {
            let errors = state.errors;
            errors[action.error] = undefined;
            return {
                ...state,
                errors: { ...errors },
            };
        }

        default:
            return state;
    }
}
