import React, { useState } from "react";
import PropTypes from "prop-types";
import Expand from "react-expand-animated";

export function Accordion(props) {
    const [open, setOpen] = useState(false);

    return (
        <div className="accordion">
            <div className="accordion__title" onClick={() => setOpen(!open)}>
                {props.title}
            </div>
            <Expand open={open} duration={200}>
                <div className="accordion__content">{props.children}</div>
            </Expand>
        </div>
    );
}

Accordion.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
};
