import React, { useEffect, useState } from "react";
import useInterval from "./useInterval";
import ReactDOM from "react-dom";
import axios from "axios";
import { getParameter } from "../lib/queryParams";
import useValidateCard from "./hooks/useValidateCard";
import { Accordion } from "./Accordion";
import texts from "../../texts/texts";
import { reducer } from "./reducers/reducers";
import { Recharge } from "./Recharge";
import { Unload } from "./Unload";
import { createStore } from "redux";
import { Provider, useDispatch, useSelector } from "react-redux";

//test

function AppWindow() {
    const dispatch = useDispatch();
    const view = useSelector((state) => state.view);
    const card = useSelector((state) => state.card);
    const errors = useSelector((state) => state.errors);
    const balance = useSelector((state) => state.balance);
    const pending = useSelector((state) => state.pending);
    const unloadStatus = useSelector((state) => state.unloadStatus);
    const isCardValid = useValidateCard();

    const [rechargeActive, setRechargeActive] = useState(false);
    const [unloadActive, setUnloadActive] = useState(false);

    useEffect(() => {
        dispatch({
            type: "UPDATE_UNLOAD_TIME",
            unloadTime: localStorage.getItem("unloadTime"),
        });
        dispatch({ type: "UPDATE_UNLOAD_TIME_DIFF" });

        let number = getParameter("p");

        if (number == null || number == "") {
            return;
        }

        dispatch({ type: "UPDATE_CARD", card: number });
        number = number.replace(/[^0-9]/g, "");
        checkBalance(number);
    }, []);

    useInterval(() => {
        dispatch({ type: "UPDATE_UNLOAD_TIME_DIFF" });
    }, 1000);

    const checkBalance = async (number) => {
        dispatch({ type: "DELETE_ERROR", error: "card" });

        if (!isCardValid(number)) {
            return;
        }

        dispatch({ type: "UPDATE_PENDING", pending: "balance" });
        await axios
            .post(`/api/balance?card=${number}`)
            .then(async (response) => {
                dispatch({
                    type: "UPDATE_BALANCE",
                    balance: response.data.balance,
                });
            })
            .catch(async (error) => {
                dispatch({
                    type: "UPDATE_BALANCE",
                    balance: null,
                });

                if (error.response.status == 404) {
                    dispatch({
                        type: "ADD_ERROR",
                        error: "card",
                        message: texts.errors.wrongNumber,
                    });
                } else {
                    dispatch({
                        type: "ADD_ERROR",
                        error: "card",
                        message: texts.errors.balanceError,
                    });
                }
            });
        dispatch({ type: "UPDATE_PENDING", pending: "" });
    };

    const getAccordions = () => {
        const list = [];
        for (const el of texts.accordions) {
            list.push(
                <Accordion title={el.title} key={el.title}>
                    <div dangerouslySetInnerHTML={{ __html: el.content }}></div>
                </Accordion>
            );
        }

        return list;
    };

    const renderRechargeButton = () => {
	if (!rechargeActive)
		return undefined;
        return <button
                className="button"
                disabled={true}
                onClick={() => {}
                    //dispatch({
                    //    type: "UPDATE_VIEW",
                    //    view: "recharge",
                    //})
                }
            >
                Guthaben aufladen
            </button>
    }

    const renderUnloadButton = () => {
	if (!unloadActive)
		return undefined;
	return 
		<button
                            className="button"
                            disabled={true}
                            onClick={() => {}
                                //dispatch({
                                //    type: "UPDATE_VIEW",
                                //    view: "unload",
                                //})
                            }
                        >
                            Guthaben entladen
                        </button>
    }

    return (
        <div>
            <div className="logo">
                <img src="/images/svg/logo.svg" alt="logo" />
                <div className="logo__label h1">Fankarte</div>
            </div>

            <p
                className="p"
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: texts.intro }}
            ></p>
            <div className="divider"></div>

            <div className="stack stack_large">
                {view != "unloadConfirm" && (
                    <div>
                        <label className="label" htmlFor="card-number">
                            Kartennummer
                        </label>
                        <input
                            type="text"
                            className={`field ${
                                errors.card ? "field_error" : ""
                            }`}
                            id="card-number"
                            value={card}
                            onChange={(e) => {
                                dispatch({
                                    type: "UPDATE_CARD",
                                    card: e.target.value,
                                });
                                dispatch({
                                    type: "DELETE_ERROR",
                                    error: "card",
                                });
                            }}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    checkBalance(card);
                                }
                            }}
                        ></input>
                        {errors.card && (
                            <div className="status status_error">
                                {errors.card.message && (
                                    <div
                                        className="status__message"
                                        dangerouslySetInnerHTML={{
                                            __html: errors.card.message,
                                        }}
                                    ></div>
                                )}
                            </div>
                        )}
                        {balance != null && !errors.card && (
                            <div className="status">
                                Dein Karten-Guthaben beträgt{" "}
                                <strong>
                                    {balance.toString().replace(".", ",")}€
                                </strong>
                            </div>
                        )}
                        {unloadStatus && (
                            <div className="status">{unloadStatus}</div>
                        )}
                    </div>
                )}
                {view == "home" && (
                    <div className="stack stack_small">
                        <button
                            className="button"
                            onClick={() => checkBalance(card)}
                            disabled={pending == "balance"}
                        >
                            Guthaben abfragen
                        </button>
                        { renderRechargeButton() }
			{ renderUnloadButton() }
                    </div>
                )}
                {view == "recharge" && false && <Recharge></Recharge>}
                {(view == "unload" && view == "unloadConfirm" && false) && (
                    <Unload></Unload>
                )}
                <div>{getAccordions()}</div>
            </div>
        </div>
    );
}

const store = createStore(reducer);

if (document.getElementById("app-window")) {
    ReactDOM.render(
        <Provider store={store}>
            <AppWindow />
        </Provider>,
        document.getElementById("app-window")
    );
}
