export default {
    intro: `
    Gude 05ER,<br/>auf Dieser Seite kannst Du das Guthaben Deiner Fankarte verwalten. Am einfachsten nutzt Du hierfür den QR-Code auf der Rückseite Deiner Fankarte. Alternativ kannst Du die Kartennummer Deiner Fankarte nachfolgend manuell eingeben. Diese findest Du auf der Kartenrückseite unten links.
    <br />
    <br />
    Aktuell kannst du deine Fankarte nur an den Paymentstationen im O-Block an Spieltagen und im Fanshop außerhalb der Spieltage, laden und entladen.
    `,
    accordions: [
        {
            title: "Nutzungsbedingungen der Guthabenkarte",
            content: `
            <p>Diese Gutscheinkarte wird von dem 1. FSV Mainz 05 e.V., Isaac-Fulda-Allee 5, 55124 Mainz (“Mainz 05“) ausgegeben. Die Karte kann nach Aufladung mit einem Guthabenbetrag in allen Standorten von Mainz 05, auch an den Catering-Stationen in der Arena und in den Fanshops von Mainz 05 sowie unter https://shop.mainz05.de ausschließlich gegen Waren und Dienstleistungen eingelöst werden.</p>
            <p>Sollte eine Zahlung aufgrund technischer Probleme nicht möglich sein, behält sich Mainz 05 vor, eine Zahlung mit dieser Karte im Einzelfall abzulehnen. Das Guthaben auf dieser Karte wird nicht verzinst.</p>
            <p>Bei verschiedenen Varianten der Fankarte, darunter die "Dauerkarte", 11er-Karte", "Guthabenkarte" für Kinder und kontolose, bzw. kartenlose Fans kann der Restwert des Gutscheins am Spieltag in bar sowie jederzeit online ausgezahlt werden. Dies erfolgt auf Kulanz; ein Rechtsanspruch hierauf besteht nicht.</p>
            <p>Mainz 05 haftet nicht bei Verlust, Entwendung, Missbrauch oder Nichtlesbarkeit. Eine Sperrung ist nicht möglich. Nicht genutzte Gutscheine verfallen grundsätzlich nach drei Jahren ab dem Ende des Jahres der letztmaligen Aufladung gemäß §§195, 199 BGB. Eine weitere Akzeptanz im Einlösungsfall nach dem Verfallsdatum ist lediglich auf Kulanz möglich.</p>
            `,
        },
        {
            title: "Nutzungshinweise am Terminal",
            content: `
            <p>Die Verwendung der Gutscheinkarte "Fankarte" an Zahlungsterminals zum Zwecke der Zahlung oder Aufladung erfolgt mittels dem auf der Karte befindlichen Magnetstreifen. Eine kontaktlose Zahlung ist mit der Gutscheinkarte nicht möglich.</p>
            <p>Es wird kein Pfand für die Gutscheinkarte "Guthabenkarte" erhoben. Die Mindestaufladung beträgt 10,- €. Der maximale Wert des Gutscheins beträgt 200,- €.</p>
            <p>Die Gutscheinfunktion auf der "Dauerkarte", "11er-Karte", "Guthabenkarte" richtet sich an Kinder und kontolose, bzw. kartenlose Fans sowie an Fans, welche mittels eines selbst auferlegten Limits Bargeld aufladen wollen und/oder keine anderen offenen Zahlungsmittel und/oder Smartphones zum Spieltag mitbringen wollen.</p>
            <p>Für schnelles und kontaktloses Zahlen ohne vorherige Aufladung steht allen anderen Personen die Zahlung mittels Giro- und Kreditkarten (Mastercard, Visa) sowie mittels Zahlungsapps auf Smartphones (z.B. Apple Pay, Google Pay) zur Verfügung.</p>
            `,
        },
        {
            title: "Umgang mit der Guthabenkarte",
            content: `
            <p>Die Auszahlung des Restguthabens der Karten sowie eines eventuell gezahlten Pfandbetrags erfolgt an Spieltagen an den Paymentstationen innerhalb de O-Blocks und außerhalb der Spieltage in den Fanshops.</p>
            `,
        },
    ],
    errors: {
        enterNumber: "Kartennummer eingeben",
        tooShort: "Kartennummer ist zu kurz",
        tooLong: "Kartennummer ist zu lang",
        unknownError: "Es ist ein Fehler aufgetreten. Bitte nochmals versuchen",
        wrongNumber: "Ungültige Kartennummer",
        wrongAmountFormat: "Ungültiges Betragsformat",
        emptyBalance: "Deine Karte enthält kein Guthaben",
        balanceError:
            "Guthaben konnte nicht abgefragt werden, bitte erneut versuchen",
    },
};
