import { useDispatch } from "react-redux";
import texts from "../../../texts/texts";
import process from "process";

export default function useValidateCard() {
    const dispatch = useDispatch();
    function isCardValid(card) {
        const cardLength = 19;
        if (!card) {
            dispatch({
                type: "ADD_ERROR",
                error: "card",
                message: texts.errors.enterNumber,
            });
            return false;
        } else if (card.length < cardLength) {
            dispatch({
                type: "ADD_ERROR",
                error: "card",
                message: texts.errors.tooShort,
            });
            return false;
        } else if (card.length > cardLength) {
            dispatch({
                type: "ADD_ERROR",
                error: "card",
                message: texts.errors.tooLong,
            });
            return false;
        } else if (!card.startsWith(process.env.MIX_CARD_PREFIX)) {
            dispatch({
                type: "ADD_ERROR",
                error: "card",
                message: texts.errors.wrongNumber,
            });
            return false;
        }

        return true;
    }

    return isCardValid;
}
